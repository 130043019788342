.JoinOmen4391d9f874a44f8eBefd28970696dca5 {
    width: 100%;
    background: white;
    text-align: center;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
}

.JoinOmen4391d9f874a44f8eBefd28970696dca5 .omen-container-main {
    text-align: center;
    background: white;
    padding: 6rem 6rem 8rem;
}

.JoinOmen4391d9f874a44f8eBefd28970696dca5 .omen-container-heading {
    margin-bottom: 3.5rem;
    margin-top: 0rem;
    font-size: 3rem;
    color: #000;
    text-align: center;
    font-family: "Fraunces", serif;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}

.JoinOmen4391d9f874a44f8eBefd28970696dca5 .omen-grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5rem 2rem;
}

.JoinOmen4391d9f874a44f8eBefd28970696dca5 .omen-grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1.25rem 3.75rem; 
}

.JoinOmen4391d9f874a44f8eBefd28970696dca5 .omen-grid-item img {
    width: 3.125rem;
    height: 3.125rem;
}

.JoinOmen4391d9f874a44f8eBefd28970696dca5 .omen-grid-item h3 {
    margin: 1.25rem 0 0.625rem; 
    font-size: 1.5rem;
    color: #077BF6;
    text-align: center;
    font-family: "Fraunces", serif;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.JoinOmen4391d9f874a44f8eBefd28970696dca5 .omen-grid-item p {
    font-size: 1rem;
    color: #000;
    text-align: center;
    font-family: "Fraunces", serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media (max-width: 768px) {
    .JoinOmen4391d9f874a44f8eBefd28970696dca5 .omen-grid-container {
        grid-template-columns: 1fr;
    }

    .JoinOmen4391d9f874a44f8eBefd28970696dca5 .omen-grid-item {
        margin-bottom: 1.25rem; 
        padding: 1.25rem; 
    }

    .JoinOmen4391d9f874a44f8eBefd28970696dca5 .omen-container-main {
        text-align: center;
        background: white;
        padding: 4rem 1rem;
    }

    .JoinOmen4391d9f874a44f8eBefd28970696dca5 .omen-container-heading {
        font-size: 2rem;
    }
}
.HomePage93ffabe374094b2488b343b6991a404d .PageQuote {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.HomePage93ffabe374094b2488b343b6991a404d .PageQuote > * {
  flex-grow: 1;
}

.HomePage93ffabe374094b2488b343b6991a404d .PageQuote .Image {
  object-fit: cover;
}

.HomePage93ffabe374094b2488b343b6991a404d .PageQuote .TextContent {
  background-color: #6950a8;
  color: white;
  padding: 3rem;
}

.HomePage93ffabe374094b2488b343b6991a404d .PageQuote .Title {
  font-family: "Bebas Neue";
  font-size: 350%;
  line-height: 100%;
}

.HomePage93ffabe374094b2488b343b6991a404d .PageQuote .Text {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 125%;
}

@media (min-width: 900px) {
  .HomePage93ffabe374094b2488b343b6991a404d .PageQuote > * {
    width: 50%;
    height: 26rem;
  }
}

@media (min-width: 1200px) {
  .HomePage93ffabe374094b2488b343b6991a404d .PageQuote .Title {
    font-size: 400%;
    line-height: 125%;
  }
}
.ShortHeader20cded99A4f646d4A7994db4a3953aa1 {
  background-color: white;
  color: #030C22;
  position: relative;
}

.ShortHeader20cded99A4f646d4A7994db4a3953aa1 .background-image {
  background-size: cover;
  background-position: top;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.3;
}

.ShortHeader20cded99A4f646d4A7994db4a3953aa1 .header-top {
  align-items: center;
  display: flex;
  height: 4rem;
  justify-content: space-between;
  padding: 0 1.5rem;
  position: relative;
  /* background-color: #a0b5d2; */
  background-color: #cce3ff;
}

.ShortHeader20cded99A4f646d4A7994db4a3953aa1 .header-top .linked-logo {
  display: flex;
  justify-content: center;
}

.ShortHeader20cded99A4f646d4A7994db4a3953aa1 .header-top .logo {
  width: 8rem;
}

.ShortHeader20cded99A4f646d4A7994db4a3953aa1 .header-top .navigation {
  margin: 1rem;
  margin-top: 2.25rem;
}

.ShortHeader20cded99A4f646d4A7994db4a3953aa1 .header-top .Navigation {
  display: none;
  flex-grow: 1;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

.ShortHeader20cded99A4f646d4A7994db4a3953aa1 .header-top .Navigation a.Link {
  color: #000;
}

.ShortHeader20cded99A4f646d4A7994db4a3953aa1 .header-top .Navigation a.Link:hover {
  color: #0550a1;
}

.ShortHeader20cded99A4f646d4A7994db4a3953aa1 .header-top .NavigationMenuButton {
  color: black;
  opacity: 0.5;
  display: flex;
}

.ShortHeader20cded99A4f646d4A7994db4a3953aa1 .header-top .NavigationMenuButton svg {
  width: 1.5rem;
  height: 1.5rem;
}

.SideCar {
  background-color: #ccc;
  padding: 2rem;
}

.SideCar .Navigation {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #500064;
  font-family: Montserrat;
  text-transform: uppercase;
}

.SideCar .Navigation .Link {
  display: block;
}
.SideCar .Navigation .Link:hover, .SideCar .Navigation .Link:active {
  filter: brightness(150%);
}

/* Larger screens */

@media (min-width: 900px) {
  .ShortHeader20cded99A4f646d4A7994db4a3953aa1 .header-top .linked-logo {
    flex-grow: 1;
  }
  .ShortHeader20cded99A4f646d4A7994db4a3953aa1 .header-top .logo {
    width: 9rem;
  }

  .ShortHeader20cded99A4f646d4A7994db4a3953aa1 .header-top .navigation {
    flex-grow: 4;
  }

  .ShortHeader20cded99A4f646d4A7994db4a3953aa1 .header-top .Navigation {
    display: flex;
  }

  .ShortHeader20cded99A4f646d4A7994db4a3953aa1 .header-top .NavigationMenuButton {
    display: none;
  }
}

@media (min-width: 1200px) {
  .ShortHeader20cded99A4f646d4A7994db4a3953aa1 .header-top {
    padding: 0 1.75rem;
    height: 5rem;
  }

  .ShortHeader20cded99A4f646d4A7994db4a3953aa1 .header-top .logo {
    width: 10rem;
  }
}
.SecondaryNav8e32b43078bd41c2B91704fc91389cea {
  padding: 1rem 0;
}
.PageQuote8e06851b5d404d438ab2F3375bb0502f {
  display: flex;
  flex-wrap: wrap;
}
.OurStory03a9688a5a6c4f51882b915812b57477 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    background-color: #fff;
    margin: 0;
    padding: 0;
}

.OurStory03a9688a5a6c4f51882b915812b57477 .background-container {
  border-radius: 0.9375rem;
  background: linear-gradient(90deg, #FFB9D4 0%, #FF317C 25%, #FF7048 50%, #1BA982 75%, #077BF6 100%);
  padding: 0.625rem;
  width: 95%;
  max-width: 56.25rem;
  height: auto;
  position: relative;
  border: 0.1875rem solid #000;
  margin: 0.5rem;
}

.OurStory03a9688a5a6c4f51882b915812b57477 .content-container {
  position: relative;
  border-radius: 0.9375rem;
  border: 0.1875rem solid #000;
  background-color: #fff;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
}

.OurStory03a9688a5a6c4f51882b915812b57477 .text {
  padding: 1.25rem;
}

.OurStory03a9688a5a6c4f51882b915812b57477 .text h1 {
  margin: 0;
  color: #000;
  font-family: "Fraunces", serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.OurStory03a9688a5a6c4f51882b915812b57477 .text p {
  color: #000;
  font-family: "Fraunces", serif;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 1.25rem 0;
}

.OurStory03a9688a5a6c4f51882b915812b57477 .text a {
  text-decoration: none;
  color: #077BF6;
  text-align: center;
  font-family: "Fraunces", serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.OurStory03a9688a5a6c4f51882b915812b57477 .text a:hover {
  text-decoration: underline;
}

.OurStory03a9688a5a6c4f51882b915812b57477 .image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.OurStory03a9688a5a6c4f51882b915812b57477 .image img {
  width: 100%;
  height: auto;
  object-fit: contain;
}


@media (min-width: 1025px) {
.OurStory03a9688a5a6c4f51882b915812b57477 {
    padding: 5rem 0rem ;
    height: 90vh;
}

  .OurStory03a9688a5a6c4f51882b915812b57477 .background-container {
    width: 100%;
    max-width: 62rem;
    height: 23rem;
    position: relative;
    margin: 1.25rem;
  }

  .OurStory03a9688a5a6c4f51882b915812b57477 .content-container {
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - -2rem);
    height: calc(100% - -2.75rem);
    top: -4rem;
    left: 0.1rem;
  }

  .OurStory03a9688a5a6c4f51882b915812b57477 .text {
    flex: 1;
    padding: 1.25rem 2.5rem 1.25rem 1.25rem ;
  }

  .OurStory03a9688a5a6c4f51882b915812b57477 .text h1 {
    color: #000;
    font-family: "Fraunces", serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0rem;
    margin-bottom: 3rem;
  }

  .OurStory03a9688a5a6c4f51882b915812b57477 .text p {
    color: #000;
    font-family: "Fraunces", serif;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;
    margin-top: 1.25rem;
    margin-bottom: 2.875rem;
  }

  .OurStory03a9688a5a6c4f51882b915812b57477 .image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem 0 1rem;
  }
}


@media (min-width: 600px) and (max-width: 1024px) {
  .OurStory03a9688a5a6c4f51882b915812b57477 .background-container {
    width: 100%;
    max-width: 50rem;
    height: auto;
    position: relative;
    margin: 1rem;
  }

  .OurStory03a9688a5a6c4f51882b915812b57477 .content-container {
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 0rem);
    height: auto;
    top: 0;
    left: 0;
    padding: 1rem;
  }

  .OurStory03a9688a5a6c4f51882b915812b57477 .text {
    flex: 1;
    padding: 1rem;
  }

  .OurStory03a9688a5a6c4f51882b915812b57477 .text h1 {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }

  .OurStory03a9688a5a6c4f51882b915812b57477 .text p {
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
  }

  .OurStory03a9688a5a6c4f51882b915812b57477 .image {
    flex: 1;
    padding: 0.5rem;
  }

  .photo-gallery-class {
    margin: 0 1rem;
    padding: 0;
  }
}


@media (max-width: 599px) {

.OurStory03a9688a5a6c4f51882b915812b57477 {
    height: 70vh;
}
  .OurStory03a9688a5a6c4f51882b915812b57477 .content-container {
    flex-direction: column-reverse;
  }

  .OurStory03a9688a5a6c4f51882b915812b57477 .text h1 {
    font-size: 1.5rem;
  }

  .OurStory03a9688a5a6c4f51882b915812b57477 .text p {
    font-size: 1rem;
  }

  .OurStory03a9688a5a6c4f51882b915812b57477 .background-container {
    margin: 0.5rem;
  }

  .OurStory03a9688a5a6c4f51882b915812b57477 .text {
    padding: 1rem;
  }

  .OurStory03a9688a5a6c4f51882b915812b57477 .image {
    padding: 0.5rem;
  }
}5
.SecondaryPageD5b6fb8c5a4a41c4Af11B03dfe9f646c .text-content {
  padding: 0 1rem;
}
.SecondaryMarketingHeader1193e9c58de3410985f53681bba0366c {
  height: 18rem;
  position: relative;
  color: #030C22;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.SecondaryMarketingHeader1193e9c58de3410985f53681bba0366c .background-image {
  background-image: url(https://mp1md-pub.s3-us-west-2.amazonaws.com/content/8PxY1Z94hSxsjVFl_regular.png);
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -10;
  opacity: 0.5;
}

.SecondaryMarketingHeader1193e9c58de3410985f53681bba0366c .header-top-wrapper {
  position: relative;
}

.SecondaryMarketingHeader1193e9c58de3410985f53681bba0366c .header-top-opacity {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-color: white;
  z-index: 0;
}

.SecondaryMarketingHeader1193e9c58de3410985f53681bba0366c .header-top {
  z-index: 1;
  position: relative;
}
.MessagePagesFadaf22877cf4c1d9b7b5a48f5e53e77 {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
.OmenImageGallery86650423089b43fe9d42E85a22d2241c {
  padding: 6rem 9rem 8rem;
  background: linear-gradient(to bottom, white 0%, #d4eaff 10%, #d4eaff 90%, white 100%);
  text-align: center;
}

.OmenImageGallery86650423089b43fe9d42E85a22d2241c .omen-image-main-container {
  text-align: center;
}

.OmenImageGallery86650423089b43fe9d42E85a22d2241c .omen-image-inner-container h1 {
  margin-bottom: 5rem;
  color: #000;
  text-align: center;
  font-family: "Fraunces", serif;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.OmenImageGallery86650423089b43fe9d42E85a22d2241c .omen-gallery-fw-img {
  margin: 1rem 0;
}

.OmenImageGallery86650423089b43fe9d42E85a22d2241c .omen-gallery-fw-img img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.OmenImageGallery86650423089b43fe9d42E85a22d2241c .inner-gallery-container {
  display: flex;
  justify-content: space-between;
  gap: 1.25rem;
}

.OmenImageGallery86650423089b43fe9d42E85a22d2241c .inner-gallery-left-container,
.OmenImageGallery86650423089b43fe9d42E85a22d2241c .inner-gallery-right-container {
  width: 100%;
}

.OmenImageGallery86650423089b43fe9d42E85a22d2241c .inner-gallery-left-container img,
.OmenImageGallery86650423089b43fe9d42E85a22d2241c .inner-gallery-right-container img {
  width: 100%;
  height: auto;
  object-fit: contain;
  margin-bottom: 1.25rem;
}

@media (max-width: 48rem) {
  .OmenImageGallery86650423089b43fe9d42E85a22d2241c {
    padding: 4rem 1rem;
  }

  .OmenImageGallery86650423089b43fe9d42E85a22d2241c .inner-gallery-container {
    flex-direction: column;
    gap: 0;
  }

  .OmenImageGallery86650423089b43fe9d42E85a22d2241c .omen-gallery-fw-img img,
  .OmenImageGallery86650423089b43fe9d42E85a22d2241c .inner-gallery-left-container img,
  .OmenImageGallery86650423089b43fe9d42E85a22d2241c .inner-gallery-right-container img {
    width: 100%;
    height: auto;
    margin-bottom: 1.25rem;
    object-fit: contain;
  }

.OmenImageGallery86650423089b43fe9d42E85a22d2241c .omen-image-inner-container h1 {
  font-size: 2rem;
}
}
.DefaultFooter43ac7e10539042ea84b7Bdbb3b56b2c9 {
  /* background-color: #500064; */
  background: linear-gradient(180deg, #FFF 0%, #D1E6FF 20%, #BFDDFF 50%, #D1E6FF 100%);
  color: white;
  min-height: 10em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 6rem 2rem;
  font-family: Montserrat;
}

.DefaultFooter43ac7e10539042ea84b7Bdbb3b56b2c9 > * {
  text-align: center;
}

.DefaultFooter43ac7e10539042ea84b7Bdbb3b56b2c9 .Navigation {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 3rem;
  justify-content: center;
  margin-bottom: 2rem;
}

.DefaultFooter43ac7e10539042ea84b7Bdbb3b56b2c9 .Navigation .Link:hover {
  text-decoration-line: underline;
  --tw-brightness: brightness(1.1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.DefaultFooter43ac7e10539042ea84b7Bdbb3b56b2c9 > .logo-wrapper {
  width: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DefaultFooter43ac7e10539042ea84b7Bdbb3b56b2c9 .LogoWrapper {
  font-size: 90%;
  --tw-text-opacity: 1;
  color: rgb(0 194 152 / var(--tw-text-opacity));
}

.DefaultFooter43ac7e10539042ea84b7Bdbb3b56b2c9 .LogoWrapper:hover {
  --tw-text-opacity: 1;
  color: white;
  transition-property: color;
  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
}

.DefaultFooter43ac7e10539042ea84b7Bdbb3b56b2c9 .Logo {
  height: 40px;
  width: 150px;
  fill: currentColor;
}

.DefaultFooter43ac7e10539042ea84b7Bdbb3b56b2c9 .GroupCopyright {
  font-size: 90%;
  color: #000;
  text-align: center;
  font-family: "Fraunces", serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.DefaultFooter43ac7e10539042ea84b7Bdbb3b56b2c9 .GroupFlowTag .PoweredBy {
  font-size: 90%;
  color: #000;
  text-align: center;
  font-family: "Fraunces", serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 3rem;
}
.MarketingCardsF5099290588a400eA59d08dc41911b5c {
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 7rem;
}

.MarketingCardsF5099290588a400eA59d08dc41911b5c .MarketingCard {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;
}

@media (min-width: 768px) {
  .MarketingCardsF5099290588a400eA59d08dc41911b5c .MarketingCard {
    gap: 6rem;
  }
}

.MarketingCardsF5099290588a400eA59d08dc41911b5c .MarketingCard .ImageOverlay {
}

@media (min-width: 768px) {
  .MarketingCardsF5099290588a400eA59d08dc41911b5c .CardTextContent {
    width: 25rem;
  }
}

.MarketingCardsF5099290588a400eA59d08dc41911b5c .CardHeading {
  color: #500064;
  font-family: Bebas Neue;
  font-size: 3rem;
  letter-spacing: 0.1rem;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 1;
}
@media (min-width: 768px) {
  .MarketingCardsF5099290588a400eA59d08dc41911b5c .CardHeading {
    padding-left: 0;
    padding-right: 0;
    line-height: 1.5;
  }
}

.MarketingCardsF5099290588a400eA59d08dc41911b5c .CardText {
  font-family: Montserrat;
  font-size: 1.25rem;
  letter-spacing: 0.05rem;
}

.MarketingCardsF5099290588a400eA59d08dc41911b5c .ButtonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
}

@media (min-width: 768px) {
  .MarketingCardsF5099290588a400eA59d08dc41911b5c .ButtonWrapper {
    justify-content: flex-start;
  }
}

.MarketingCardsF5099290588a400eA59d08dc41911b5c .Button {
  background-color: #780050;
  color: white;
  font-family: 'Bebas Neue'; 
  font-weight: 700; 
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
}
.omen-container {
            max-width: 1000px;
            padding: 20px;
            text-align: center;

            margin-left: auto;
            margin-right: auto;
        }



        .omen-container h1 {
            margin-bottom: 40px;
            font-size: 2rem;
        }

        .omen-grid-container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 40px;
        }

        .omen-grid-item {
            text-align: center;
            padding: 20px;
        }

        .omen-grid-item img {
            width: 50px;
            height: 50px;
        }

        .omen-grid-item h3 {
            color: blue;
            margin: 20px 0 10px;
            font-size: 1.2rem;
        }

        .omen-grid-item p {
            font-size: 1rem;
            color: #333;
        }

        @media (max-width: 768px) {
            .omen-grid-container {
                grid-template-columns: 1fr;
            }

            .omen-grid-item {
                margin-bottom: 20px;
            }
        }
.AboutLevelingPageE3b86aad9bef4d19B1f95ab21db4a58e .Button {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  background: rgb(120, 0, 80);
  letter-spacing: 0.1rem;
}
.MarketingBody632633af71164f918c7f51286c368a1a {
  padding: 2em;
  background-color: #f9f5e3;
}
.HomeHeaderB8ddd4b56add4287A67cD53709633e2e {
    background-color: white;
    position: relative;
    color: #030C22;
}

.HomeHeaderB8ddd4b56add4287A67cD53709633e2e .header-top-wrapper {
    position: relative;
    height: 6rem;
}

.HomeHeaderB8ddd4b56add4287A67cD53709633e2e .header-top-opacity {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.85;
    background-color: white;
}

@media (min-width: 900px) {
    .HomeHeaderB8ddd4b56add4287A67cD53709633e2e .header-top-wrapper {
        height: 5rem;
        /* position: fixed;
        width: 100%;
        z-index: 99; */
    }
}

.HomeHeaderB8ddd4b56add4287A67cD53709633e2e .hero-banner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
  background-image: url(https://mp1md-pub.s3-us-west-2.amazonaws.com/content/8PxY1Z94hSxsjVFl_regular.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  color: #fff;
}

@media (min-width: 768px) {
  .HomeHeaderB8ddd4b56add4287A67cD53709633e2e .hero-banner-container {
    height: 90vh;
  }
}

.HomeHeaderB8ddd4b56add4287A67cD53709633e2e .hero-content {
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
  max-width: 37.5rem;
  padding: 1.25rem;
}

@media (min-width: 768px) {
}

.HomeHeaderB8ddd4b56add4287A67cD53709633e2e .hero-content .hero-header {
  color: #fff;
  text-align: center;
  text-shadow: .15rem .15rem 0 #000, -.15rem .15rem 0 #000, -.15rem -.15rem 0 #000, .15rem -.15rem 0 #000;
  font-family: Fraunces, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 3rem;
  line-height: 4rem;
}

@media (min-width: 768px) {
    .HomeHeaderB8ddd4b56add4287A67cD53709633e2e .hero-content .hero-header {
      font-size: 4rem;
      line-height: normal;
    }
}

.HomeHeaderB8ddd4b56add4287A67cD53709633e2e .hero-content .hero-header .external-content p {
  margin: 0;
}

.HomeHeaderB8ddd4b56add4287A67cD53709633e2e .hero-content .subheader {
}

.HomeHeaderB8ddd4b56add4287A67cD53709633e2e .hero-content .buttons {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  column-gap: 1.5rem;
  row-gap: 1rem;
}

@media (min-width: 768px) {
  .HomeHeaderB8ddd4b56add4287A67cD53709633e2e .hero-content .buttons {
    flex-direction: row;
    justify-content: center;
    align-items: start;
  }
}

.HomeHeaderB8ddd4b56add4287A67cD53709633e2e .hero-content .buttons .Button {
  border-radius: 0.625rem;
}

.HomeHeaderB8ddd4b56add4287A67cD53709633e2e .hero-content .buttons .Button.hero-button-2 {
  background-color: #aaaaaacc;
  border: 0.125rem solid #077BF6;
  line-height: 1rem;
}

.HomeHeaderB8ddd4b56add4287A67cD53709633e2e .hero-content .buttons .Button.hero-button-2:hover {
  background-color: #077bf6;
}
.MoreQuestions71f042f71be848f187018bcb7e852118 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    margin: 0;
    background-color: white;
}

.MoreQuestions71f042f71be848f187018bcb7e852118 .faq-main-container {
    padding: 1.25rem;
    width: 100%;
    max-width: 43.75rem;
    text-align: center;
}

.MoreQuestions71f042f71be848f187018bcb7e852118 h2 {
    color: #077BF6;
    font-family: "Fraunces", serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.MoreQuestions71f042f71be848f187018bcb7e852118 h3 {
    color: #000;
    font-family: "Fraunces", serif;
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 0;
    margin-bottom: 4rem;
}

.MoreQuestions71f042f71be848f187018bcb7e852118 details {
    border-bottom: 1px solid #ddd;
    padding: 0.625rem 0;
    text-align: left;
    margin-bottom: 1rem;
}

.MoreQuestions71f042f71be848f187018bcb7e852118 summary {
    color: #000;
    font-family: "Fraunces", serif;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    cursor: pointer;
    position: relative;
    list-style: none;
    padding-right: 2.1875rem;
}

.MoreQuestions71f042f71be848f187018bcb7e852118 summary::after {
    content: '+';
    width: 2.1875rem;
    height: 2.1875rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    font-size: 1.75rem;
    flex-shrink: 0;
}

.MoreQuestions71f042f71be848f187018bcb7e852118 details[open] summary::after {
    content: '-';
}

.MoreQuestions71f042f71be848f187018bcb7e852118 p {
    margin: 0;
    padding: 0.625rem 0;
    text-align: left;
}

.MoreQuestions71f042f71be848f187018bcb7e852118 .buttons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 5.25rem;
    margin-bottom: 2.25rem;
}

.MoreQuestions71f042f71be848f187018bcb7e852118 .member-btn {
    border-radius: 0.625rem;
    border: 1px solid #077BF6;
    background: #077BF6;
    color: #FFF;
    text-align: center;
    font-family: "Fraunces", serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    padding: 0.625rem 1.25rem;
    cursor: pointer;
}

.MoreQuestions71f042f71be848f187018bcb7e852118 .member-btn:hover {
    background-color: #005bb5;
}

.MoreQuestions71f042f71be848f187018bcb7e852118 .events-btn {
    color: #000;
    text-align: center;
    font-family: "Fraunces", serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    border-radius: 0.625rem;
    border: 2px solid #077BF6;
    background-color: #077BF600;
    padding: 0.625rem 1.25rem;
    cursor: pointer;
}

.MoreQuestions71f042f71be848f187018bcb7e852118 .events-btn:hover {
  background-color: #077BF611;
}

@media (max-width: 48rem) {
    .MoreQuestions71f042f71be848f187018bcb7e852118 .buttons {
        flex-direction: column;
        align-items: center;
    }
    .MoreQuestions71f042f71be848f187018bcb7e852118 .member-btn, .MoreQuestions71f042f71be848f187018bcb7e852118 .events-btn {
        width: 100%;
        max-width: 20rem; 
        margin-bottom: 1rem; 
    }

.MoreQuestions71f042f71be848f187018bcb7e852118 summary {
    font-size: 1.25rem;
}

.MoreQuestions71f042f71be848f187018bcb7e852118 h3 {
    font-size: 2rem;
}
}
.SecondaryMarketingHero612fd25d416c4140977829992aa1eeb4 {
  align-items: center;
  justify-content: center;
  color: black;
  display: flex;
  flex-grow: 1;
  position: relative;
}

@media screen and (min-width: 768px) {
  .SecondaryMarketingHero612fd25d416c4140977829992aa1eeb4 {
    padding-left: 5rem;
    justify-content: start;
  }
}

.SecondaryMarketingHero612fd25d416c4140977829992aa1eeb4::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--accent-color-1-10);
  opacity: 0.3;
  z-index: -1;
}

.SecondaryMarketingHero612fd25d416c4140977829992aa1eeb4 .title {
  font-family: var(--font-1);
  font-size: 300%;
  font-weight: 500;
  line-height: 100%;
}

@media (min-width: 640px) {
  .SecondaryMarketingHero612fd25d416c4140977829992aa1eeb4 .title {
    font-size: 400%;
  }
}

.SecondaryMarketingHero612fd25d416c4140977829992aa1eeb4 .button > a {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  color: white;
  font-family: var(--font-1);
  font-weight: 600;
  font-size: 110%;
  text-transform: uppercase;
  display: inline-block;
  padding: 0.5rem 2rem;
  background-color: #ed2e38;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.SecondaryMarketingHero612fd25d416c4140977829992aa1eeb4 .button > a:hover {
  --tw-brightness: brightness(1.1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}